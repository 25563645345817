import { GitBookIcon, GitHubIcon, KCCIcon, TelegramIcon, TwitterIcon } from "../icons";

export const governanceUrl = "https://snapshot.org/#/";
export const governanceSpace = "tikudefi.eth";

export enum SocialLink {
    TWITTER = "TWITTER",
    TELEGRAM = "TELEGRAM",
    GITHUB = "GITHUB",
    GITBOOK = "GITBOOK",
    EXPLORER = "EXPLORER"
}
export const socialLinks: {[key in SocialLink] : {url: string, icon: React.FC, alt: string}} = {
    [SocialLink.TWITTER]: {
        url: "https://twitter.com/tikutoken",
        icon: TwitterIcon,
        alt: "Twitter account link"
    },
    [SocialLink.TELEGRAM]: {
        url: "https://t.me/tikutoken",
        icon: TelegramIcon,
        alt: "Telegram group link"
    },
    [SocialLink.GITHUB]: {
        url: "https://github.com/TIKU-DeFi",
        icon: GitHubIcon,
        alt: "Github organization link"
    },
    [SocialLink.GITBOOK]: {
        url: "https://docs.tikutoken.com",
        icon: GitBookIcon,
        alt: "GitBook documentation link"
    },
    [SocialLink.EXPLORER]: {
        url: "https://explorer.kcc.io/en/token/0xd4b52510719c594514ce7fed6cc876c03278ccf8",
        icon: KCCIcon,
        alt: "Block explorer link"
    }
}