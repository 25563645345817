import React from 'react'
import Loading from './Loading/Loading'

function ThemedSuspense() {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <Loading noMargin />
    </div>
  )
}

export default ThemedSuspense
