import { InjectedConnector } from '@web3-react/injected-connector'
// import { NetworkConnector } from '@web3-react/network-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

const POLLING_INTERVAL = 12000
const RPC_URLS: { [chainId: number]: string } = {
  321: 'https://rpc-mainnet.kcc.network',
  322: 'https://rpc-testnet.kcc.network'
}

export const injected = new InjectedConnector({ supportedChainIds: [321, 322] })

// export const network = new NetworkConnector({
//   urls: { 321: RPC_URLS[321], 322: RPC_URLS[322] },
//   defaultChainId: 321
// })

export const walletconnect = new WalletConnectConnector({
  rpc: { 321: RPC_URLS[321] },
  qrcode: true,
  pollingInterval: POLLING_INTERVAL
})
