export default {
  backdrop: {
    base:
          'fixed inset-0 z-40 flex items-end bg-black backdrop-blur-md bg-opacity-75 sm:items-center sm:justify-center'
  },
  input: {
    base: 'block w-full text-sm focus:outline-none dark:text-gray-50 leading-5 rounded-md border-white dark:bg-opacity-25',
    active:
    'border-white dark:focus:border-green-600 dark:focus:ring-green-300 dark:bg-gray-900'
  },
  select: {
    base: 'block w-full text-sm focus:outline-none dark:text-gray-50 leading-5 rounded-md border-white dark:bg-opacity-25',
    active:
    'border-white dark:focus:border-green-600 dark:focus:ring-green-300 dark:bg-gray-900'
 
  },
  primary: {
    base: 'text-white bg-red-600 border border-transparent',
    active: 'active:bg-red-600 hover:bg-purple-700 focus:ring focus:ring-purple-300',
    disabled: 'opacity-50 cursor-not-allowed',
  },
}
