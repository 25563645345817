import React, { ReactNode, useCallback, useMemo, useState } from "react";

interface SidebarProviderProps {
    children: ReactNode;
}

interface SidebarContextType {
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
    closeSidebar: () => void;
}

const initialState = {
    isSidebarOpen: false,
    toggleSidebar: () => {},
    closeSidebar: () => {},
};
// create context
export const SidebarContext = React.createContext<SidebarContextType>(initialState);

export const SidebarProvider = ({ children }: SidebarProviderProps) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = useCallback(() => {
        setIsSidebarOpen(!isSidebarOpen);
    }, [isSidebarOpen, setIsSidebarOpen]);

    const closeSidebar = useCallback(() => {
        setIsSidebarOpen(false);
    }, [setIsSidebarOpen]);

    const value = useMemo(
        () => ({
            isSidebarOpen,
            toggleSidebar,
            closeSidebar,
        }),
        [isSidebarOpen, closeSidebar, toggleSidebar]
    );

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};
