/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
const routes = [
  {
    path: "/",
    icon: "HomeIcon",
    name: "Home"
  },  
  {
    path: '/AUTO-KCS', // the url
    icon: 'GiftIcon', // the component being exported from icons/index.js
    name: 'Auto-paid $KCS', // name that appear in Sidebar
  },
  {
    path: '/LSD',
    icon: 'DollarIcon',
    name: 'Staking',
  },
  {
    path: "/LSD-Fund",
    icon: "BarChartIcon",
    name: "LSD Fund"
  },
  {
    path:"/nfts",
    icon: "CardsIcon",
    name: "NFTs"
  },
]

export default routes
