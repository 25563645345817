import { BigNumber, ethers } from "ethers"
import network from "../constants/network"
import { initialContracts, provider } from "../contexts/ContractsContext"
import { MarketContextAction, MarketContextActionType } from "../types/contexts"
import { FixMeLater } from "../types/helpers"
import { fromWeiNumber } from "./format"

export const updateAccountMarketData = (account: string, dispatch: React.Dispatch<MarketContextAction>) => {
    initialContracts.TIKU.read.getNumberOfDividendTokenHolders().then((holders: FixMeLater) => {
        initialContracts.TIKU.read.balanceOf(account).then((balance: any) => {
            dispatch({
                type: MarketContextActionType.SET_TIKU_BALANCE,
                payload: balance / 1e18
            })
            initialContracts.TIKU.read.getAccountDividendsInfo(account).then((result: FixMeLater) => {
                provider.getBalance(account || '').then((balance: BigNumber) => {
                    dispatch({
                        type: MarketContextActionType.SET_KCS_BALANCE,
                        payload: fromWeiNumber(balance)
                    })
                    dispatch({
                        type: MarketContextActionType.SET_PAID_KCS,
                        payload: parseInt(result[4]._hex, 16) - parseInt(result[3]._hex, 16)
                    })
                    dispatch({
                        type: MarketContextActionType.SET_LAST_PAID,
                        payload: parseInt(result[5]._hex, 16) * 1000
                    })
                    dispatch({
                        type: MarketContextActionType.SET_PAYOUT_PROGRESS,
                        payload:
                            100 -
                            (parseInt(result[2]._hex, 16) / parseInt(holders._hex, 16)) * 100
                    })
                    dispatch({
                        type: MarketContextActionType.SET_NEXT_PAYOUT_VALUE,
                        payload: parseInt(result[3]._hex, 16) / 1e18
                    })
                })
            })
        })
    })
}

async function getAmountsOut(quoteAmount: string, path: string[]) {
    return await initialContracts.KOFFEE_ROUTER.read.functions.getAmountsOut(quoteAmount, path, {
        gasLimit: 1000000000000
    })
}

async function getKcUsdcPrice() {
    const functionResponse = await getAmountsOut(`${1 * Math.pow(10, network.wkcs.decimals)}`, [
        network.wkcs.address,
        network.usdc.address
    ])
    const priceInUsd = Number(functionResponse.amounts[1].toString()) / Math.pow(10, network.usdc.decimals)
    return priceInUsd
}

async function getKcsTikuPrice() {
    const functionResponse = await getAmountsOut(`${1 * Math.pow(10, 18)}`, [
        network.wkcs.address,
        network.tikuContractAddress
    ])
    const priceInUsd = Number(functionResponse.amounts[1].toString()) / Math.pow(10, network.usdc.decimals)
    return priceInUsd
}

const getPrices = (dispatch: React.Dispatch<MarketContextAction>) => {
    let kcsPrice: number;

    getKcUsdcPrice().then((price) => {
        kcsPrice = price
        dispatch({ type: MarketContextActionType.SET_KCS_PRICE, payload: kcsPrice })

        getKcsTikuPrice().then((price) => {
            dispatch({ type: MarketContextActionType.SET_TIKU_PRICE, payload: kcsPrice / price });
            dispatch({ type: MarketContextActionType.SET_TIKU_PRICE_KCS, payload: 1 / price})
        }
            
        )
    })
}

const getTotals = (dispatch: React.Dispatch<MarketContextAction>) => {
    initialContracts.TIKU.read.getTotalDividendsDistributed().then((total: BigNumber) => {
        dispatch({ type: MarketContextActionType.SET_TOTAL_PAID, payload: parseFloat(ethers.utils.formatEther(total.add(BigInt(1847387385739295424996)).add(BigInt(161080266972289749574)))) })
    })

    initialContracts.TIKU.read.totalSentToLSDFund().then((total: BigNumber) => {
        dispatch({
            type: MarketContextActionType.SET_TOTAL_SENT_TO_LSD,
            payload: fromWeiNumber(total)
        })
    })
}

export const updateGeneralMarketData = (dispatch: React.Dispatch<MarketContextAction>) => {
    getPrices(dispatch);
    getTotals(dispatch);
}
