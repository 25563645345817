import { Web3Provider } from "@ethersproject/providers";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { Button, Card, CardBody, Modal, ModalBody, ModalHeader } from "@windmill/react-ui";
import React, { ReactNode, useEffect } from "react";
import useWalletConnector from "../../hooks/useWalletConnector";
import { CheckCircleIcon, LogInIcon, XCircleIcon } from "../../icons";
import { injected, walletconnect } from "./connectors";
import { useEagerConnect, useInactiveListener } from "./hooks";


const MetaMaskLogo = (
    <img className="w-12" src={require("../../assets/img/metamask-logo.png")} alt="MetaMask Logo" />
);

const WalletConnectLogo = (
    <img
        className="w-12"
        src={require("../../assets/img/walletconnect-logo.png")}
        alt="Wallet Connect Logo"
    />
);

enum ConnectorNames {
    Injected = "MetaMask",
    WalletConnect = "WalletConnect",
}

const connectorsByName: { [connectorName in ConnectorNames]: any } = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect,
};

const connectorsLogos: { [connectorName in ConnectorNames]: ReactNode } = {
    [ConnectorNames.Injected]: MetaMaskLogo,
    [ConnectorNames.WalletConnect]: WalletConnectLogo,
};

function getErrorMessage(error: Error) {
    if (error instanceof NoEthereumProviderError) {
        return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
        return "You're connected to an unsupported network.";
    } else if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect
    ) {
        return "Please authorize this website to access your Ethereum account.";
    } else {
        console.error(error);
        return "An unknown error occurred. Check the console for more details.";
    }
}

export default function WalletConnector() {
    const {isOpen, openModal, closeModal} = useWalletConnector();

    const context = useWeb3React<Web3Provider>();
    const { connector, account, activate, error } = context;

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = React.useState<any>();

    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    useEffect(() => {
        closeModal();
    }, [connector, closeModal]);
    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();

    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector);

    return (
        <>
            {/* Button / account info / wrong network info */}
            {error instanceof UnsupportedChainIdError ? (
                <span className="bg-red-500 rounded-lg pl-2 pr-4 py-2 text-white font-semibold flex whitespace-nowrap">
                    <XCircleIcon className="mr-2" />
                    Wrong network
                </span>
            ) : account ? (
                <span className="border-green-200 border-2 rounded-lg pl-2 pr-4 py-2 text-green-200 font-semibold flex whitespace-nowrap">
                    <CheckCircleIcon className="mr-2" />
                    {account.substr(0, 4)} ... {account.substr(account.length - 4, account.length)}
                </span>
            ) : (
                <Button
                    className="gradient-light moving-gradient-light-hover font-semibold hover:scale-105 text-black transition-transform duration-200 ease-in-out transform"
                    onClick={openModal}
                >
                    <LogInIcon className="mr-2" />
                    CONNECT
                </Button>
            )}

            <Modal
                className="absolute screen-center gradient-dark-darker pb-0 p-6 rounded-lg min-w-max"
                isOpen={isOpen}
                onClose={closeModal}
            >
                <ModalHeader className="absolute top-3">Connect your wallet</ModalHeader>
                <ModalBody className="mt-4 p-6 pb-0">
                    {Object.keys(connectorsByName).map((name) => {
                        const currentConnector = connectorsByName[name as ConnectorNames];
                        const activating = currentConnector === activatingConnector;
                        const connected = currentConnector === connector;
                        const disabled =
                            !triedEager || !!activatingConnector || connected || !!error;

                        return (
                            <Card
                                className={`mb-4 cursor-pointer gradient-dark transition duration-250 transform hover:-translate-y-1 hover:scale-110 ${
                                    disabled && "cursor-not-allowed opacity-30"
                                }`}
                                key={name}
                                onClick={() => {
                                    setActivatingConnector(currentConnector);
                                    activate(connectorsByName[name as ConnectorNames]);
                                }}
                            >
                                <CardBody className="flex items-center whitespace-nowrap text-white">
                                    {connectorsLogos[name as ConnectorNames]}
                                    <span className="ml-4">{name}</span>
                                </CardBody>
                            </Card>
                        );
                    })}
                </ModalBody>
            </Modal>
        </>
    );
}
