import { Button } from "@windmill/react-ui";
import React, { ReactElement, useContext } from "react";
import SectionCard from "../components/Cards/SectionCard";
import { Skeleton } from "../components/Skeleton/Skeleton";
import network from "../constants/network";
import { LSDContext } from "../contexts/LSDContext";
import { MarketContext } from "../contexts/MarketContext";
import { fromWeiNumber } from "../utils/format";

interface Props {}

function Home({}: Props): ReactElement {
    const {
        state: { totalPaid },
    } = useContext(MarketContext);
    const {
        state: { yieldsInKCS, TVLs, totalLSD, TIKUBurned },
    } = useContext(LSDContext);
    const APY = yieldsInKCS.AY
        ? (100 * fromWeiNumber(yieldsInKCS.AY)) / fromWeiNumber(TVLs[network.tikuContractAddress])
        : null;
    const totalRewards =
        totalPaid != 0 && !totalLSD.eq(0) ? totalPaid + fromWeiNumber(totalLSD) : 0;
    return (
        <div className="p-2 lg:p-8 text-white">
            <div className="flex w-full h-96 justify-center flex-col items-center">
                <img
                    src={process.env.PUBLIC_URL + "/tiku-green.png"}
                    alt="TIKU Logo"
                    className="h-48 w-48"
                ></img>
                <h1 className="text-4xl font-bold">TIKU DeFi</h1>
                <h2 className="text-2xl font-semibold">
                    The #1 sustainable reward ecosystem on KCC{" "}
                </h2>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-6">
                <SectionCard title="Earn $KCS dividends">
                    <div className="text-3xl font-bold text-green-400 flex items-center justify-center">
                        {" "}
                        {APY ? (
                            APY?.toLocaleString()
                        ) : (
                            <Skeleton className="bg-green-400 w-24 h-8 mr-2" />
                        )}{" "}
                        % APY
                    </div>
                </SectionCard>
                <SectionCard title="Total TIKU burned">
                    <div className="text-2xl text-orange-400 font-bold text-center flex items-center justify-center">
                        {!TIKUBurned.eq(0) ? (
                            `${(fromWeiNumber(TIKUBurned) + 70640354.286).toLocaleString(undefined, {maximumFractionDigits: 0})} (${((fromWeiNumber(TIKUBurned) + 70640354.286) /1e7).toFixed(2)} %)` 
                        ) : (
                            <Skeleton className="bg-orange-500 w-48 h-8 mr-2" />
                        )}{" "}
                    </div>
                </SectionCard>
                <SectionCard title="Total paid to holders">
                    <div className="text-3xl font-bold text-center flex items-center justify-center">
                        {" "}
                        {totalRewards ? (
                            totalRewards.toLocaleString()
                        ) : (
                            <Skeleton className="bg-white-400 w-24 h-8 mr-2" />
                        )}{" "}
                        <img
                            className="w-7 h-7 ml-2"
                            alt="KuCoin Shares Logo"
                            src={require("../assets/img/kcs-white.png")}
                        />
                    </div>
                </SectionCard>
            </div>
            <SectionCard title="TIKU Token features" className="flex flex-col items-center">
                <div className="flex m-2 lg:mx-10 justify-around flex-wrap items-center">
                    <div className="flex md:w-5/12 m-4 p-8 flex-nowrap items-center flex-col rounded-2xl text-center gradient-dark-darker">
                        <img
                            src={require("../assets/img/kucoin-dividends.svg")}
                            alt=""
                            className="mt-2 h-24"
                        />
                        <h4 className="text-xl my-2">
                            4%
                            <br />
                            Loyalty staking dividends
                        </h4>
                        <p className="paragraph-3">
                            LSD fund pays the dividends based on{" "}
                            <strong>how long the holder have been staking </strong>their TIKU. It{" "}
                            <strong>incentivizes holding </strong>and{" "}
                            <strong>increases the price of TIKU</strong> using special buyback and
                            burn mechanism.
                            <br />
                        </p>
                    </div>
                    <div className="flex md:w-5/12 m-4 p-8 flex-nowrap items-center flex-col rounded-2xl text-center gradient-dark-darker">
                        <img
                            src={require("../assets/img/kucoin-back.svg")}
                            alt=""
                            className="mt-2 h-24"
                            width="834"
                            height="150"
                        />
                        <h4 className="text-xl my-2">
                            8%
                            <br />
                            Auto-paid $KCS rewards
                        </h4>
                        <p className="paragraph-4">
                            Hold at least 31,415 TIKU to be eligible for auto-paid $KCS. Rewards are
                            <strong>automatically paid every 60 minutes</strong> or can be claimed
                            manually.
                        </p>
                    </div>
                    <div className="flex md:w-5/12 m-4 p-8 flex-nowrap items-center flex-col rounded-2xl text-center gradient-dark-darker">
                        <img
                            src={require("../assets/img/splash.svg")}
                            alt=""
                            className="mt-2 h-24"
                            width="122"
                        />
                        <h4 className="text-xl my-2">
                            3%
                            <br />
                            Liquidity pool
                        </h4>
                        <p className="paragraph-5">
                            3% of every transaction is added to the liquidity pool on KoffeeSwap to
                            make the TIKU<strong> price less volatile</strong>.
                        </p>
                    </div>
                    <div className="flex md:w-5/12 m-4 p-8 flex-nowrap items-center flex-col rounded-2xl text-center gradient-dark-darker">
                        <img
                            src={require("../assets/img/whale.svg")}
                            alt=""
                            className="mt-2 h-24"
                        />
                        <h4 className="text-xl my-2">
                            3% sell fee
                            <br />
                            and anti-whale measures
                        </h4>
                        <p className="paragraph-6">
                            Any transaction <strong>selling more than 0.1%</strong> of total supply
                            <strong>will be rejected</strong>. &nbsp;
                            <br />
                            <br />
                            Additionally, <strong>extra 3% fee is applied</strong> to all selling
                            transactions.
                        </p>
                    </div>
                </div>
                <Button
                    layout="outline"
                    target="_blank"
                    tag="a"
                    href="https://docs.tikutoken.com/tiku-tokenomics"
                >
                    Read more in docs
                </Button>
            </SectionCard>
        </div>
    );
}

export default Home;
