import React from 'react'
import SidebarContent from './SidebarContent'


function DesktopSidebar() {
  return (
    <aside className="z-30 flex-shrink-0 hidden w-64 overflow-y-auto bg-gray-800 bg-opacity-75 lg:block">
      <SidebarContent />
    </aside>
  )
}

export default DesktopSidebar
