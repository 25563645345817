import React from "react";

interface SkeletonProps {
    className?: string;
}

export const Skeleton = ({ className }: SkeletonProps) => {
    return (
        <div className="animate-pulse flex">
            <div className={`rounded bg-gray-400 ${className}`} />
        </div>
    );
};
