import React, { useContext } from "react";
import { SidebarContext } from "../contexts/SidebarContext";
import { CoffeeIcon, MenuIcon } from "../icons";
import WalletConnector from "./WalletConnector/WalletConnector";

function Header() {
    const { toggleSidebar } = useContext(SidebarContext);

    return (
        <header className="z-40 py-4 shadow-bottom bg-gray-800 bg-opacity-75">
            <div className="container flex items-center justify-between lg:justify-end h-full px-6 mx-auto text-green-300">
                {/* <!-- Mobile hamburger --> */}
                <button
                    className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-green"
                    onClick={toggleSidebar}
                    aria-label="Menu"
                >
                    <MenuIcon className="w-6 h-6" aria-hidden="true" />
                </button>
                <a
                    className="border-2 rounded-lg border-white border-solid px-4 py-2 glow font-bold hover:scale-105 text-white transition-transform duration-200 ease-in-out transform flex items-center mr-4"
                    href="https://koffeeswap.exchange/#/swap?inputCurrency=KCS&outputCurrency=0xd4b52510719c594514ce7fed6cc876c03278ccf8"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Buy TIKU <CoffeeIcon className="ml-1" />
                </a>
                <WalletConnector />
            </div>
        </header>
    );
}

export default Header;
