import React, { ReactElement } from 'react'

interface Props {
    title: string;
    children: React.ReactNode;
    className?: string;
}

function SectionCard({
    title,
    children,
    className
}: Props): ReactElement {
    return (
        <div className={`${className} bg-gray-800 bg-opacity-75 px-2 py-4 lg:p-4 xl:p-6 rounded-lg`}>
            <h2 className="text-2xl font-semibold mb-2 text-center">{title}</h2>
            {children}
        </div>
    )
}

export default SectionCard
