/* eslint-disable no-unused-vars */
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { contractConfig, Contracts, contracts as contractsConfig } from '../constants/contracts'
import network from '../constants/network'
import { ReadAndWriteContracts } from '../types/contracts'

export const provider = new ethers.providers.JsonRpcProvider(network.RPC)

export const initialContracts = Object.keys(contractsConfig).reduce((result, contractName) => {
  const config = contractsConfig[contractName as Contracts] as contractConfig

  const read = new ethers.Contract(config.address, config.ABI, provider)

  return { ...result, [contractName]: { read, write: null } }
}, {} as ReadAndWriteContracts)

export const ContractsContext = createContext<ReadAndWriteContracts>(initialContracts)

export const ContractsProvider = ({ children }: { children: ReactNode }) => {
  const { library, account } = useWeb3React<Web3Provider>()

  const [contracts, setContracts] = useState<ReadAndWriteContracts>(initialContracts)

  // whenever library or account changes, new write contract instances are updated
  useEffect(() => {
    const signer = library?.getSigner()
    const newContracts = Object.keys(contractsConfig).reduce((result, contractName) => {
      const config = contractsConfig[contractName as Contracts] as contractConfig

      let write
      if (signer) {
        write = new ethers.Contract(config.address, config.ABI, signer)
      } else {
        write = null
      }

      return {
        ...result,
        [contractName]: { read: initialContracts[contractName as Contracts].read, write }
      }
    }, {} as ReadAndWriteContracts)

    setContracts(newContracts)
  }, [library, account])

  return <ContractsContext.Provider value={contracts}>{children}</ContractsContext.Provider>
}
