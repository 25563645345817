import { Interface, JsonFragment } from "@ethersproject/abi"
import { Web3Provider } from "@ethersproject/providers"

export function getLibrary(provider: any): Web3Provider {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
  }
  
  export const encodeInputData =  (abi: JsonFragment[], functionName: string, args: any[]) => {
    const iface = new Interface(abi);
    return iface.encodeFunctionData(functionName, args);
  }
  
  export const decodeOutputData =  (abi: JsonFragment[], functionName: string, data: string) => {
    const iface = new Interface(abi);
    return iface.decodeFunctionResult(functionName, data);
  } 