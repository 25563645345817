import React from "react";
import "./custom.css";

interface LoadingProps {
    noMargin?: boolean;
}

function Loading({ noMargin }: LoadingProps) {
    return (
        <img
            alt="TIKU logo loader"
            src={process.env.PUBLIC_URL + "/tiku-green.png"}
            className={`w-1/4 ${
                noMargin ? "" : "mt-48"
            } inline-block align-middle hue-rotate`}
        />
    );
}

export default Loading;
