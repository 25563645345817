/* eslint-disable no-unused-vars */
import { ethers } from 'ethers';

type ContextAction <A, P> = { type: A; payload: P };

export interface MarketContextState {
    tikuPrice: number;
    tikuPriceKCS: number;
    tikuBalance: number;
    paid: number;
    lastPaid: number;
    nextPayoutProgress: number;
    totalPaid: number;
    nextPayoutValue: number;
    kcsBalance: number;
    kcsPrice: number;
    totalSentToLSDFund: number;
}

export enum MarketContextActionType {
    SET_TIKU_PRICE,
    SET_TIKU_PRICE_KCS,
    SET_TIKU_BALANCE,
    SET_PAID_KCS,
    SET_LAST_PAID,
    SET_PAYOUT_PROGRESS,
    SET_TOTAL_PAID,
    SET_NEXT_PAYOUT_VALUE,
    SET_KCS_BALANCE,
    SET_KCS_PRICE,
    SET_TOTAL_SENT_TO_LSD,
}

export type MarketContextAction = ContextAction< MarketContextActionType, number>

export interface ContractsContextState {
    tikuContract: ethers.Contract | null
}

export enum ContractsContextActionType {
    SET_TIKU_CONTRACT
}

export type ContractsContextAction = ContextAction<ContractsContextActionType, ethers.Contract>
