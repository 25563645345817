import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import Layout from "./containers/Layout";


function App() {
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    className: "gradient-dark-darker-important",
                    style: { color: "#fff" },
                }}
                containerStyle={{
                    top: "84px",
                }}
            />
            <Router>
                <AccessibleNavigationAnnouncer />
                <Switch>
                    <Route path="/" render={(props) => <Layout {...props} />} />
                </Switch>
            </Router>
        </>
    );
}

export default App;
