import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import network from "../constants/network";
import { getAccountOwnedNFTsInBatch } from "../utils/fetch";

export type NFTData = { token: string; id: BigNumber };

export type NFTsData = NFTData[];

interface NFTsContextType {
    loading: boolean;
    allNFTs: NFTsData;
    refreshCollection: () => void;
}

const initialData = {
    loading: true,
    allNFTs: [] as NFTsData,
    refreshCollection: () => {},
};

export const NFTsContext = createContext<NFTsContextType>(initialData);

export const NFTsProvider = ({ children }: { children: ReactNode }) => {
    const recognizedNFTs = [network.NFTCharacters1];

    const [loading, setLoading] = useState(true);

    const { account } = useWeb3React();

    const emptyCollection = recognizedNFTs.reduce(
        (result, token) => ({ ...result, [token]: [] }),
        {}
    );

    const [NFTCollection, setNFTCollection] =
        useState<{ [token: string]: BigNumber[] }>(emptyCollection);

    const loadCollection = useCallback(async (account: string | null | undefined) => {
        setLoading(true);
        if (account) {
            await Promise.all(
                recognizedNFTs.map(async (token) => {
                    const tokenIds: BigNumber[] = await getAccountOwnedNFTsInBatch(account, token);
                    setNFTCollection((prev) => ({ ...prev, [token]: tokenIds }));
                })
            );
        } else {
            setNFTCollection(emptyCollection);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        loadCollection(account);
    }, [account]);

    const refreshCollection = useCallback(() => loadCollection(account), [account]);

    const allNFTs = useMemo(
        () =>
            Object.keys(NFTCollection).reduce(
                (all, token) => [...all, ...NFTCollection[token].map((id) => ({ token, id }))],
                [] as { token: string; id: BigNumber }[]
            ),
        [NFTCollection]
    );

    const result = useMemo(
        () => ({ loading, allNFTs, refreshCollection }),
        [loading, allNFTs, refreshCollection]
    );

    return <NFTsContext.Provider value={result}>{children}</NFTsContext.Provider>;
};
