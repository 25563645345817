import { lazy } from 'react';
import Home from '../pages/Home';

// use lazy for better code splitting, a.k.a. load faster
const Dashboard = lazy(() => import('../pages/Dashboard'))
const LSD = lazy(() => import('../pages/LSD'))
const LSDFund = lazy(() => import("../pages/LSDFund"));
const NFTs = lazy(() => import('../pages/NFTs'));

/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */
const routes = [
  {
    path: "/",
    component: Home
  },
  {
    path: '/AUTO-KCS', // the url
    component: Dashboard, // view rendered
  },
  {
    path: '/LSD',
    component: LSD,
  },
  {
    path: '/LSD-Fund',
    component: LSDFund
  },
  {
    path: '/nfts',
    component: NFTs
  },
]

export default routes
