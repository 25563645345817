import React, { useContext } from "react";
import { NavLink, Route } from "react-router-dom";
import { socialLinks } from "../../constants/urls";
import { MarketContext } from "../../contexts/MarketContext";
import * as Icons from "../../icons";
import routes from "../../routes/sidebar";
import { Skeleton } from "../Skeleton/Skeleton";
import {ReactComponent as KuPayIcon } from "../../assets/img/kupay-logo-white.svg";
import {ReactComponent as KuNameIcon} from "../../assets/img/kuname-logo-white.svg";
import {ReactComponent as VixenPunksIcon} from "../../assets/img/vixen-punks-white.svg";
interface IconProps {
    icon: string;
    className: string;
}

function Icon({ icon, ...props }: IconProps) {
    const Icon = Icons[icon as keyof typeof Icons];
    return <Icon {...props} />;
}

function SidebarContent() {
    const {
        state: { tikuPrice },
    } = useContext(MarketContext);

    return (
        <div className="py-4 text-gray-400 h-full flex flex-col">
            <span className="flex ml-6 p-2 text-lg font-bold text-gray-200">
                <img
                    alt="TIKU logo"
                    className="mr-2 w-12 h-12"
                    src={process.env.PUBLIC_URL + "/tiku-green.png"}
                />
                <span className="self-center">TIKU DeFi</span>
            </span>
            <nav className="flex justify-evenly px-4 mt-2">
                {Object.values(socialLinks).map(({ url, alt, icon: IconComponent }) => (
                    <a
                        key={url}
                        className="text-white transition-colors duration-300 hover:text-green-400 h-5 w-5"
                        href={url}
                        target="_blank" 
                    >
                        {" "}
                        <IconComponent aria-label={alt} />{" "}
                    </a>
                ))}
            </nav>
            <div className="mt-4 flex justify-center">
                <span className="font-bold text-yellow-300 flex justify-center items-center">
                    TIKU $
                    {tikuPrice ? (
                        tikuPrice.toFixed(6)
                    ) : (
                        <Skeleton className="ml-1 w-16 h-6 bg-yellow-300" />
                    )}
                </span>
            </div>
            <div className="flex justify-between flex-col h-full">
                <ul className="mt-4">
                    {routes.map((route) => (
                        <li className="relative px-6 py-3" key={route.name}>
                            <NavLink
                                exact
                                to={route.path}
                                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-200"
                                activeClassName="text-gray-100"
                            >
                                <Route exact path={route.path}>
                                    <span
                                        className="absolute inset-y-0 left-0 w-1 bg-green-600 rounded-tr-lg rounded-br-lg"
                                        aria-hidden="true"
                                    ></span>
                                </Route>
                                <Icon className="w-5 h-5" aria-hidden="true" icon={route.icon} />
                                <span className="ml-4">{route.name}</span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
                <div>
                    <div className="px-6 uppercase tracking-widest font-normal text-center border-opacity-20 pt-2 mt-8">
                        Partners
                    </div>
                    <div className="relative px-6 py-3 mt-2 flex flex-col items-center w-full text-sm gap-4">
                        {/* <span className="px-1.5 py-0.5 bg-red-500 hue-rotate rounded-full text-white absolute right-3 top-0 z-10">
                            NEW
                        </span> */}
                        <a
                            href="https://kupay.finance/"
                            target="_blank"
                            className="w-full flex items-center border-2 px-4 py-3 text-md font-semibold hover:border-white hover:text-purple-500 rounded-lg text-white border-purple-500"
                        >
                            <KuPayIcon className="h-6 mr-2" />
                        </a>
                        <a
                            href="https://kuname.domains/"
                            target="_blank"
                            className="w-full flex items-center border-2 px-4 py-3 text-md font-semibold hover:border-white hover:text-green-400 rounded-lg text-white border-green-400"
                        >
                            <KuNameIcon className="h-6 mr-2" />
                        </a>
                        <a
                            href="https://vixenpunks.com/"
                            target="_blank"
                            className="w-full flex items-center border-2 px-4 py-3 text-md font-semibold hover:border-white hover:text-teal-400 rounded-lg text-white border-teal-400"
                        >
                            <VixenPunksIcon className="h-6 mr-2" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SidebarContent;
