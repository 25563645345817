import { useWeb3React } from "@web3-react/core";
import { BigNumber, Contract } from "ethers";
import React, { createContext, ReactNode, useEffect, useMemo, useReducer } from "react";
import UniswapPairABI from "../constants/abi/IUniswapV2PairMin.json";
import UniswapRouterABI from "../constants/abi/IUniswapV2RouterMin.json";
import LSDFundABI from "../constants/abi/LSDFund.json";
import LSDViewerABI from "../constants/abi/LSDViewer.json";
import {
    APYSharesDayOffset,
    LSDBagsTotalMultiplier,
    LSDBagTokens,
    LSDBagTokenType,
    LSDFundRewardsDistribution,
    MasterchefEnum,
    masterchefs
} from "../constants/LSD";
import network from "../constants/network";
import { IUniswapV2PairMin, IUniswapV2RouterMin, LSDFund, LSDViewer } from "../typechain";
import { LSDBag, LSDBags } from "../types/LSD";
import { getERC20Contract, getViewingContract } from "../utils/contracts";
import { fromWeiNumber, toWei } from "../utils/format";
import { initialContracts } from "./ContractsContext";

type TotalShares = {
    [address: string]: BigNumber;
};
interface LSDContextState {
    bags: LSDBags;
    tokens: LSDBagTokenType[];
    minBagAge: number;
    approved: string[]; // array of approved contracts
    totalShares: TotalShares;
    LSDFundAssets: LSDFundAssets;
    LSDFundValueInKCS: BigNumber;
    yieldsInKCS: Yields;
    TVLInKCS: BigNumber;
    TVLs: TVLs;
    totalMultiplier: number;
    TIKUBurned: BigNumber;
    nextDividendPayoutAfter: BigNumber;
    totalLSD: BigNumber;
}

interface Yields {
    WY: BigNumber | null;
    MY: BigNumber | null;
    AY: BigNumber | null;
}

export const initialState = {
    bags: Array<LSDBag>(),
    tokens: LSDBagTokens,
    minBagAge: 4 * 60 * 60 * 1000, // 4hrs
    approved: [],
    totalShares: {},
    LSDFundAssets: {},
    LSDFundValueInKCS: BigNumber.from(0),
    yieldsInKCS: {
        WY: null,
        MY: null,
        AY: null,
    },
    TVLInKCS: BigNumber.from(0),
    TVLs: {},
    totalMultiplier: LSDBagsTotalMultiplier,
    TIKUBurned: BigNumber.from(0),
    nextDividendPayoutAfter: BigNumber.from(0),
    totalLSD: BigNumber.from(0),
};

export enum LSDContextActionType {
    SET_BAGS,
    SET_APPROVED_TOKENS,
    SET_TOTAL_SHARES,
    SET_LSD_ASSETS,
    SET_LSDFUND_VALUE,
    SET_YIELDS,
    SET_TVL_IN_KCS,
    SET_TVLS,
    SET_TIKU_BURNED,
    SET_NEXT_DIVIDEND_PAYOUT,
    SET_TOTAL_LSD,
}

export type LSDContextAction =
    | {
          type: LSDContextActionType.SET_BAGS;
          payload: LSDBags;
      }
    | { type: LSDContextActionType.SET_APPROVED_TOKENS; payload: string[] }
    | { type: LSDContextActionType.SET_TOTAL_SHARES; payload: TotalShares }
    | { type: LSDContextActionType.SET_LSD_ASSETS; payload: LSDFundAssets }
    | { type: LSDContextActionType.SET_LSDFUND_VALUE; payload: BigNumber }
    | { type: LSDContextActionType.SET_YIELDS; payload: Yields }
    | { type: LSDContextActionType.SET_TVL_IN_KCS; payload: BigNumber }
    | { type: LSDContextActionType.SET_TVLS; payload: TVLs }
    | { type: LSDContextActionType.SET_TIKU_BURNED; payload: BigNumber }
    | { type: LSDContextActionType.SET_NEXT_DIVIDEND_PAYOUT; payload: BigNumber }
    | { type: LSDContextActionType.SET_TOTAL_LSD; payload: BigNumber };

export interface LSDContextUpdaters {
    bags: () => void;
    TVL: () => void;
}

const reducer = (state: LSDContextState, action: LSDContextAction) => {
    switch (action.type) {
        case LSDContextActionType.SET_BAGS:
            return { ...state, bags: action.payload };
        case LSDContextActionType.SET_APPROVED_TOKENS:
            return { ...state, approved: action.payload };
        case LSDContextActionType.SET_TOTAL_SHARES:
            return { ...state, totalShares: action.payload };
        case LSDContextActionType.SET_LSD_ASSETS:
            return { ...state, LSDFundAssets: action.payload };
        case LSDContextActionType.SET_LSDFUND_VALUE:
            return { ...state, LSDFundValueInKCS: action.payload };
        case LSDContextActionType.SET_YIELDS:
            console.log(action.payload);
            return { ...state, yieldsInKCS: action.payload };
        case LSDContextActionType.SET_TVL_IN_KCS:
            return { ...state, TVLInKCS: action.payload };
        case LSDContextActionType.SET_TVLS:
            return { ...state, TVLs: action.payload };
        case LSDContextActionType.SET_TIKU_BURNED:
            return { ...state, TIKUBurned: action.payload };
        case LSDContextActionType.SET_NEXT_DIVIDEND_PAYOUT:
            return { ...state, nextDividendPayoutAfter: action.payload };
        case LSDContextActionType.SET_TOTAL_LSD:
            return { ...state, totalLSD: action.payload };
    }
};

export const LSDContext = createContext<{
    state: LSDContextState;
    dispatch: React.Dispatch<LSDContextAction>;
    update: LSDContextUpdaters;
}>({ state: initialState, dispatch: () => null, update: { bags: () => {}, TVL: () => {} } });

export const LSDProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { account } = useWeb3React();

    useEffect(() => {
        // Computation of total shares gets more and more difficult over time,
        // therefore it's loaded only on initialization
        loadTotalLSD(dispatch);
        loadTotalShares(dispatch);
        getLSDAssetsAndAPY(dispatch);
        computeTVL(dispatch);
        getTIKUBurned(dispatch);
        getNextDividendPayout(dispatch);
    }, []);

    useEffect(() => {
        loadBags(account, dispatch);
        checkApproved(account, dispatch);
    }, [account]);

    const update = useMemo(
        () => ({
            bags: () => loadBags(account, dispatch),
            TVL: () => computeTVL(dispatch),
        }),
        [account]
    );

    const contextValue = useMemo(() => {
        return { state, dispatch, update };
    }, [state, dispatch, update]);

    return <LSDContext.Provider value={contextValue}>{children}</LSDContext.Provider>;
};

async function loadTotalLSD(dispatch: React.Dispatch<LSDContextAction>) {
    const totalLSD = await initialContracts.LSD_BAG.read.totalLSD();
    dispatch({ type: LSDContextActionType.SET_TOTAL_LSD, payload: totalLSD });
}

async function loadTotalShares(dispatch: React.Dispatch<LSDContextAction>) {
    const Viewer = getViewingContract<LSDViewer>(LSDViewerABI)(network.LSDViewerContractAddress);
    const totalShares: TotalShares = {};
    for (let index = 0; index < LSDBagTokens.length; index++) {
        const tokenAddress = LSDBagTokens[index].address;
        let totalAmount = BigNumber.from(0);
        let dayToContinue = BigNumber.from(0);
        let finished = false;
        while (!finished) {
            const result = await Viewer.getTotalSharesOfToken(
                tokenAddress,
                dayToContinue,
                totalAmount,
                APYSharesDayOffset
            );
            totalAmount = totalAmount.add(result.totalShares);
            dayToContinue = result.pausedOnDay;
            finished = result.finished;
        }
        totalShares[tokenAddress] = totalAmount;
    }
    dispatch({ type: LSDContextActionType.SET_TOTAL_SHARES, payload: totalShares });
}

async function loadBags(
    account: string | null | undefined,
    dispatch: React.Dispatch<LSDContextAction>
) {
    let bags: LSDBags = Array<LSDBag>();
    if (account) {
        try {
            bags = await initialContracts.LSD_BAG.read.getBagsOwnedBy(account);
        } catch (e) {
            bags = [];
        }
    } else {
        bags = [];
    }

    dispatch({ type: LSDContextActionType.SET_BAGS, payload: bags });
}

function checkApproved(
    account: string | null | undefined,
    dispatch: React.Dispatch<LSDContextAction>
) {
    let approved: string[] = [];
    if (account) {
        LSDBagTokens.forEach(async (token) => {
            getERC20Contract(token.address)
                .allowance(account, network.LSDBagContractAddress)
                .then((allowance) => {
                    if (allowance.gt(0)) {
                        approved.push(token.address);
                    }
                });
        });
    }
    dispatch({ type: LSDContextActionType.SET_APPROVED_TOKENS, payload: approved });
}

type LSDFundAssets = { [address: string]: LSDFundAsset };
interface LSDFundAsset {
    rewardToken: string;
    rewardsPerBlock: BigNumber;
    rewardsPerBlockInKCS: BigNumber;
    amount: BigNumber;
    masterchef: string;
    valueInKCS: BigNumber;
}

async function computeRewardPerBlock(
    address: string,
    Viewer: Contract,
    totalTokensPerBlock: BigNumber,
    allocPoint: BigNumber,
    totalAllocPoint: BigNumber,
    ownedTokens: BigNumber,
    totalTokensInPool: BigNumber
): Promise<BigNumber> {
    switch (address) {
        case MasterchefEnum.KUSWAP: {
            const stakingPercent = await Viewer.stakingPercent();
            const percentDec = await Viewer.percentDec();
            return totalTokensPerBlock
                .mul(allocPoint)
                .div(totalAllocPoint)
                .mul(stakingPercent)
                .div(percentDec)
                .mul(ownedTokens)
                .div(totalTokensInPool);
        }

        // case MasterchefEnum.KUKAFE: {
        //     const blockNumber = await provider.getBlockNumber();
        //     const multiplier = await Viewer.getMultiplier(blockNumber, blockNumber + 1);
        //     return totalTokensPerBlock
        //         .mul(multiplier)
        //         .mul(allocPoint)
        //         .div(totalAllocPoint)
        //         .mul(ownedTokens)
        //         .div(totalTokensInPool);
        // }
        case MasterchefEnum.MJT: {
            return totalTokensPerBlock
                .mul(allocPoint)
                .div(totalAllocPoint)
                .mul(ownedTokens)
                .div(totalTokensInPool);
        }
    }
    return BigNumber.from(0);
}

async function getLSDAssetsAndAPY(dispatch: React.Dispatch<LSDContextAction>) {
    const LSDFundViewer = getViewingContract<LSDFund>(LSDFundABI)(network.LSDFundContractAddress);
    const LSDFundAssets = await LSDFundViewer.getAssets();

    let assets: LSDFundAssets = {};

    await Promise.all(
        masterchefs.map(async (masterchef) => {
            const Viewer = getViewingContract(masterchef.ABI)(masterchef.address);
            const totalAllocPoint = await Viewer.totalAllocPoint();
            const totalTokensPerBlock = await Viewer[masterchef.perBlockGetterName]();

            await Promise.all(
                masterchef.targetedPools.map(async (poolId) => {
                    const { allocPoint, lpToken } = await Viewer.poolInfo(poolId);
                    const LPTokenContract = getERC20Contract(lpToken);
                    const totalTokensInPool = await LPTokenContract.balanceOf(masterchef.address);
                    const ownedTokens =
                        LSDFundAssets.find(
                            ({ assetAddress, masterchefAddress }) =>
                                assetAddress === lpToken && masterchefAddress === masterchef.address
                        )?.balance || BigNumber.from(0);

                    const TokenViewer =
                        getViewingContract<IUniswapV2PairMin>(UniswapPairABI)(lpToken);

                    let totalValueOfPool = BigNumber.from(0);

                    const [token0, token1] = [
                        await TokenViewer.token0(),
                        await TokenViewer.token1(),
                    ];
                    if (token0 === network.wkcs.address) {
                        totalValueOfPool = (await getERC20Contract(token0).balanceOf(lpToken)).mul(
                            2
                        );
                    } else {
                        if (token1 === network.wkcs.address) {
                            totalValueOfPool = (
                                await getERC20Contract(token1).balanceOf(lpToken)
                            ).mul(2);
                        } else {
                            console.log(token0, token1);
                            //TODO
                            throw "LSD Context: Handle case when LP token doesn't consist of WKCS";
                        }
                    }

                    const valueInKCS = totalValueOfPool.mul(ownedTokens).div(totalTokensInPool);

                    const rewardsPerBlock = await computeRewardPerBlock(
                        masterchef.address,
                        Viewer,
                        totalTokensPerBlock,
                        allocPoint,
                        totalAllocPoint,
                        ownedTokens,
                        totalTokensInPool
                    );

                    const rewardsPerBlockInKCS = rewardsPerBlock
                        .mul(
                            await getTokenPricePer1KCS(
                                masterchef.routerAddress,
                                masterchef.rewardTokenAddress
                            )
                        )
                        .div(BigNumber.from(10).pow(18));

                    assets = {
                        ...assets,
                        [lpToken]: {
                            rewardToken: masterchef.rewardTokenAddress,
                            rewardsPerBlock,
                            rewardsPerBlockInKCS,
                            amount: ownedTokens,
                            masterchef: masterchef.address,
                            valueInKCS,
                        },
                    };
                })
            );
        })
    );

    dispatch({ type: LSDContextActionType.SET_LSD_ASSETS, payload: assets });
    const totalSentToLSDFund = await initialContracts.TIKU.read.totalSentToLSDFund();
    computeTotals(assets, totalSentToLSDFund, dispatch);
}

async function getTokenPricePer1KCS(router: string, tokenAddress: string): Promise<BigNumber> {
    return (
        await getViewingContract<IUniswapV2RouterMin>(UniswapRouterABI)(router).getAmountsOut(
            BigNumber.from(10).pow(18),
            [tokenAddress, network.wkcs.address],
            {
                gasLimit: 1000000000000,
            }
        )
    )[1];
}

function computeTotals(
    assets: LSDFundAssets,
    totalSentToLSDFund: BigNumber,
    dispatch: React.Dispatch<LSDContextAction>
) {
    const compoundedAmount =
        LSDFundRewardsDistribution.COMPOUND + LSDFundRewardsDistribution.DIVIDENDS;

    const totalRewardsPerBlockInKCS = Object.values(assets).reduce(
        (total: BigNumber, { rewardsPerBlockInKCS }) => total.add(rewardsPerBlockInKCS),
        BigNumber.from(0)
    );

    const LSDFundValue = Object.values(assets).reduce(
        (total: BigNumber, { valueInKCS }) => total.add(valueInKCS),
        BigNumber.from(0)
    );
    dispatch({ type: LSDContextActionType.SET_LSDFUND_VALUE, payload: LSDFundValue });
    const blocksPerHour = (60 * 60) / 3; // 1 block per 3 seconds

    const perHourReward =
        (fromWeiNumber(totalRewardsPerBlockInKCS) * blocksPerHour) / fromWeiNumber(LSDFundValue);
    const hourlyQuocient =
        (1 + perHourReward * compoundedAmount) *
        (1 + LSDFundRewardsDistribution.BURN * 0.04 * perHourReward);
    // const hourlyQuocient = hourlyQuocientBase * ()
    const dailyQuocient = hourlyQuocient ** 24;
    const weeklyQuocient = dailyQuocient ** 7;
    const monthlyQuocient = dailyQuocient ** 30;
    const annualQuocient = dailyQuocient ** 356;

    const daysSinceLaunch =
        (Date.now() - new Date(2021, 6, 22, 0).getTime()) / (1000 * 60 * 60 * 24);
    const dailyFundGrowth = fromWeiNumber(totalSentToLSDFund) / daysSinceLaunch;
    const weeklyFundGrowth = dailyFundGrowth * 7;
    const monthlyFundGrowth = dailyFundGrowth * 30;
    const annualFundGrowth = dailyFundGrowth * 365;

    const [WY, MY, AY] = [
        [weeklyQuocient, weeklyFundGrowth],
        [monthlyQuocient, monthlyFundGrowth],
        [annualQuocient, annualFundGrowth],
    ].map(([quocient, growth]) =>
        toWei(
            ((quocient * LSDFundRewardsDistribution.DIVIDENDS) /
                (LSDFundRewardsDistribution.DIVIDENDS + LSDFundRewardsDistribution.COMPOUND)) *
                (fromWeiNumber(LSDFundValue) + growth)
        )
    );

    dispatch({ type: LSDContextActionType.SET_YIELDS, payload: { WY, MY, AY } });
}

type TVLs = { [address: string]: BigNumber };

async function computeTVL(dispatch: React.Dispatch<LSDContextAction>) {
    let TVLInKCS = BigNumber.from(0);
    let TVLs: TVLs = {};
    await Promise.all(
        LSDBagTokens.map(async ({ address, router }) => {
            const tokenBalance = await getERC20Contract(address).balanceOf(
                network.LSDBagContractAddress
            );
            TVLs = {
                ...TVLs,
                [address]: tokenBalance
                    .mul(await getTokenPricePer1KCS(router, address))
                    .div(BigNumber.from(10).pow(18)),
            };
            TVLInKCS = TVLInKCS.add(
                tokenBalance
                    .mul(await getTokenPricePer1KCS(router, address))
                    .div(BigNumber.from(10).pow(18))
            );
        })
    );
    dispatch({ type: LSDContextActionType.SET_TVL_IN_KCS, payload: TVLInKCS });
    dispatch({ type: LSDContextActionType.SET_TVLS, payload: TVLs });
}

async function getTIKUBurned(dispatch: React.Dispatch<LSDContextAction>) {
    const TIKUViewer = getERC20Contract(network.tikuContractAddress);
    const burnedAmount = await TIKUViewer.balanceOf(network.BURN_ADDRESS);
    dispatch({ type: LSDContextActionType.SET_TIKU_BURNED, payload: burnedAmount });
}

async function getNextDividendPayout(dispatch: React.Dispatch<LSDContextAction>) {
    const LSDFundViewer = getViewingContract<LSDFund>(LSDFundABI)(network.LSDFundContractAddress);
    const nextDividendPayoutAfter = await LSDFundViewer.nextDividendPayoutAfter();
    dispatch({
        type: LSDContextActionType.SET_NEXT_DIVIDEND_PAYOUT,
        payload: nextDividendPayoutAfter,
    });
}
