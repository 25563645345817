import { BigNumberish, ethers } from "ethers";

export const trimWithEllipsis = (text: string, length: number): string => text.length > length + 3 ? text.slice(0, length) + "..." : text;

// For tokens with 18 decimals
export const fromWei = (number: BigNumberish, decimals: number = 2) => (parseFloat(ethers.utils.formatEther(number))).toLocaleString(undefined, {maximumFractionDigits: decimals})

export const fromWeiNumber = (number: BigNumberish) => (parseFloat(ethers.utils.formatEther(number)))

export const toWei = (number: number) => ethers.utils.parseEther(number.toString());

export const getAge = (timeDifference: number): string => {

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerWeek = msPerDay * 7;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;
  
    if (timeDifference < msPerMinute) {
        const secs = Math.round(timeDifference/1000)
        return secs > 1 ? secs + ' seconds' : secs + ' second';   
    }
  
    else if (timeDifference < msPerHour) {
        const mins = Math.round(timeDifference/msPerMinute)
        return mins > 1 ? mins + ' minutes' : mins + ' minutes';   
    }
  
    else if (timeDifference < msPerDay ) {
        const hours = Math.round(timeDifference/msPerHour )
        return hours > 1 ? hours + ' hours' : hours + ' hour';  
    }
  
    else if (timeDifference < msPerWeek) {
        const days = Math.round(timeDifference/msPerDay);
        return days > 1 ? days + " days" : days + " day";
    }
    else if (timeDifference < msPerMonth) {
        const weeks = Math.round(timeDifference/msPerWeek);
        return `${weeks} ${weeks > 1 ? "weeks" : "week"}`;
    }
  
    else if (timeDifference < msPerYear) {
        const months = Math.round(timeDifference/msPerMonth);
        return `${months} ${months > 1 ? "months" : "month"}`; 
    }
  
    const years = Math.round(timeDifference/msPerYear);
    return `${years} ${years > 1 ? "years" : "year"}`;  
    
  }