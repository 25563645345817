/* eslint-disable no-unused-vars */
const mainnet = {
  RPC: 'https://rpc-mainnet.kcc.network',
  chainId: 321,
  tikuContractAddress: '0xD4b52510719C594514CE7FED6CC876C03278cCf8',
  wkcs: {
    address: '0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521',
    decimals: 18
  },
  usdc: {
    address: '0x980a5AfEf3D17aD98635F6C5aebCBAedEd3c3430',
    decimals: 18
  },
  kuswapRouterAddress: "0xA58350d6dEE8441aa42754346860E3545cc83cdA",
  LSDBagContractAddress: "0x318f70FeAC66fc5EDED771255973128B7d76994A",
  LSDViewerContractAddress: "0x447231Ef5f02FbB2201c0d3166B04648071caD99",
  LSDFundContractAddress: "0x54D8fFC520458e8e34046215644d1F18FADB5eAE",
  NFTCharacters1: "0x446E927eDE2F41e3B4c192c964F584905d4b048C",
  LSDNFTMinter: "0xE42DE03f2c167E8521db71C62A13e13Cdce737Ca",
  NFTStaking: "0x775d15Ec0623CC8Bc0B19297E069D86445f317C4",
  nodeURL : "https://rpc-mainnet.kcc.network" // try official RPC
}

const testnet = {
  RPC: 'https://rpc-testnet.kcc.network',
  chainId: 322,
  tikuContractAddress: '0xda5afB72DE10ADB6c7EB2870C592f10358C1c14A',
  // KoffeeSwap WKCS, other dexes have other address
  wkcs: {
    address: "0xB296bAb2ED122a85977423b602DdF3527582A3DA",
    decimals: 18
  },
  kuswapRouterAddress: "0xc5f442007e08e3b13C9f95fA22F2a2B9369d7C8C",
  usdc: {
    address: "0xD6c7E27a598714c2226404Eb054e0c074C906Fc9",
    decimals: 18
  },
  LSDBagContractAddress: "0x14AeB9C88b98b11371e8426c018709E29C58e28c",
  LSDViewerContractAddress: "0xC8ED3Cc1Ff88a185E3FC3637b0Cd97075B4d9f59",
}

// switch between the mainnet and the testnet here
const network = mainnet;

export default {
  ...network,
  koffeeRouterAddress: '0xc0fFee0000C824D24E0F280f1e4D21152625742b',
  BURN_ADDRESS: "0x000000000000000000000000000000000000dEaD"
}
