import React, { Suspense, useContext, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Loading from "../components/Loading/Loading";
import Sidebar from "../components/Sidebar";
import ThemedSuspense from "../components/ThemedSuspense";
import { SidebarContext } from "../contexts/SidebarContext";
import routes from "../routes";
import { FixMeLater } from "../types/helpers";
import Main from "./Main";

// TODO after props are put into context
function Layout(props: FixMeLater) {
    const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);
    const location = useLocation();

    const { totalPaid } = props;

    useEffect(() => {
        closeSidebar();
    }, [location, closeSidebar]);

    return (
        <div className={`flex h-screen gradient-dark  ${isSidebarOpen && "overflow-hidden"}`}>
            <Sidebar />
            <div className="flex flex-col flex-1 w-full">
                <Header />
                <Main>
                    {totalPaid !== 0 ? (
                        <Suspense fallback={<ThemedSuspense />}>
                            <Switch>
                                {routes.map((route, i) => {
                                    return route.component ? (
                                        <Route
                                            key={i}
                                            exact={true}
                                            path={`${route.path}`}
                                            render={() => <route.component />}
                                        />
                                    ) : null;
                                })}
                            </Switch>
                        </Suspense>
                    ) : (
                        <div className="w-full h-full flex justify-center">
                            <Loading />
                        </div>
                    )}
                    <div className="text-sm p-2 lg:p-4 xl:p-8 text-center text-gray-200 text-opacity-75">
                        The Content shared on this website is for information purpose only and,
                        thus, should not be considered as financial advice. Trading/Investing is
                        risky and you should never invest more than you can afford to lose.
                        Cryptocurrencies are risky. Never invest more than you can afford to lose.
                        You alone assume the sole responsibility of evaluating the merits and risks
                        associated with the use of any information or other Content on the Site
                        before making any decisions based on such information or other Content.
                    </div>
                </Main>
            </div>
        </div>
    );
}

export default Layout;
