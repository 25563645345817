import { BigNumber, ethers } from "ethers";
import IERC721EnumerableABI from "../constants/abi/IERC721Enumerable.json";
import network from "../constants/network";
import { IERC721Enumerable } from "../typechain";
import { getViewingContract } from "./contracts";
import { decodeOutputData, encodeInputData } from "./web3";
export interface Proposal {
  id: string;
  title: string;
}

const fetchSnapshot = async (query: string): Promise<Proposal[]> => fetch("https://hub.snapshot.org/graphql", {
  method: "POST",
  body: JSON.stringify({
    query
  }),
  headers: {
    'content-type': 'application/json'
  }
}).then(result => result.json()).then(json => json.data.proposals)

export const fetchActiveProposals = async () => {
  const query = `{
            proposals(
              where: {
                space_in: ["tikudefi.eth"],
                state: "active"
              },
              orderBy: "created",
              orderDirection: asc
            ) {
              id
              title
            }
          }`
  return fetchSnapshot(query);
}

export const getAccountOwnedNFTsInBatch = async (account: string, NFTAddress: string): Promise<BigNumber[]> => {
  if (!account) {
    return [];
  }
  const NFTContract = getViewingContract<IERC721Enumerable>(IERC721EnumerableABI)(NFTAddress);
  const balance = (await NFTContract.balanceOf(account)).toNumber();
  if (balance === 0) {
    return [];
  }
  const inputData: string[] = [];
  for (let index = 0; index < balance; index++) {
    inputData.push(encodeInputData(IERC721EnumerableABI, "tokenOfOwnerByIndex", [account, `0x${index.toString(16)}`])); 
  }

  const requests = constructCurrentNodeRequests(NFTAddress, inputData);
  const rawResult = await batchReadFromNode(requests);
  console.log(rawResult)
  return rawResult.map(({result, id} : {result: string, id: number}) => (decodeOutputData(IERC721EnumerableABI,"tokenOfOwnerByIndex", result)));
}

export const constructHistoricNodeRequests = (fromBlock: number, toBlock: number, blockPeriodicity: number, contractToRead: string, inputData: string) => {
  const result = [];
  for (let blockNumber = fromBlock; blockNumber <= toBlock; blockNumber += blockPeriodicity) {
    result.push([
      {
        from: ethers.constants.AddressZero,
        to: contractToRead,
        gas: "0x1bd7c",
        data: inputData
      },
      `0x${blockNumber.toString(16)}`
    ])
  }
  return result;
}

/**
 * Returns valid array of requests from current block
 * @param contractToRead Contract address that will be read
 * @param inputData Array of ABI encoded input data calls
 */
export const constructCurrentNodeRequests = (contractToRead: string, inputData: string[]) =>
  inputData.map(data => ([{
    from: ethers.constants.AddressZero,
    to: contractToRead,
    gas: "0x1bd7c",
    data
  }, "latest"]))


export const batchReadFromNode = async (requests: any[]) => {
  const query = requests.map((req, id) => ({ method: "eth_call", params: req, id, jsonrpc: "2.0" }));
  console.log(query)
  try {
    const response = await fetch(network.nodeURL, {
      method: "POST",
      body: JSON.stringify(
        query
      ),
      headers: {
        'content-type': 'application/json'
      }
    });
    const json = await response.json();
    return json;
  } catch (err) {
    console.log("Error while fetching API");
  }
} 
