import { BigNumber, BigNumberish, ContractInterface } from "ethers";
import KuswapMasterchefABI from "./abi/KuswapMasterchef.json";
import MojitoSwapMasterchefABI from "./abi/MojitoSwapMasterchef.json";
import network from "./network";
export interface LSDBagTokenType {
    name: string,
    address: string;
    multiplier: number;
    knottingFee: number;
    unknottingFee: number;
    minBagAmount: BigNumberish;
    router: string;
}

// Quarter of year to make estimate
export const APYSharesDayOffset = 62;

export const LSDBagsTotalMultiplier = 40;

// We don't need to fetch this data as it won't change very often
export const LSDBagTokens: LSDBagTokenType[] = [
    {
        name: "TIKU",
        address: network.tikuContractAddress,
        multiplier: 40,
        knottingFee: 3,
        unknottingFee: 4,
        minBagAmount: BigNumber.from(10).pow(18).mul(500000),
        router: network.koffeeRouterAddress
    },
    // {
    //     name: "Testing Token",
    //     address: "0x05FBb27f65081A27A1982301Cf3d463cf1fC9dB0" ,
    //     multiplier: 40,
    //     knottingFee: 5,
    //     unknottingFee: 10,
    //     minBagAmount: BigNumber.from(10).pow(18).mul(350000)
    // }
]
interface Masterchef {
    address: string;
    rewardTokenAddress: string;
    targetedPools: number[] // pid of pools we are interested in getting information about
    perBlockGetterName: string;
    ABI: ContractInterface;
    routerAddress: string;
}

export enum MasterchefEnum{
    KUSWAP = "0x62974CE5d662f9045265716a3e64eaAfC258779F",
    // KUKAFE = "0x3Cd0651a06b293e6c21C894aFb71cC8251802fc5",
    MJT = "0xfdfcE767aDD9dCF032Cbd0DE35F0E57b04495324"
}


export const masterchefs: Masterchef[] = [
    {
        address: MasterchefEnum.KUSWAP,
        rewardTokenAddress: "0x4A81704d8C16d9FB0d7f61B747D0B5a272badf14",
        targetedPools: [1],
        perBlockGetterName: "KUSPerBlock",
        ABI: KuswapMasterchefABI,
        routerAddress: "0xA58350d6dEE8441aa42754346860E3545cc83cdA"
    },
    // {
    //     address: "0x243E46d50130F346beDE1d9548B41c49c6440872",
    //     rewardTokenAddress:"0xBd451b952dE19F2C7bA2c8c516b0740484E953B2",
    //     targetedPools: [0],
    //     perBlockGetterName: "kudexPerBlock",
    //     ABI: KudexMasterchefABI,
    //     routerAddress: "0x6074e20633D2D8FbdF82119867a81581cabe06dD"
    // },
    // {
    //     address: MasterchefEnum.KUKAFE,
    //     rewardTokenAddress: "0x516F50028780B60e2FE08eFa853124438f9E46a7",
    //     targetedPools: [14],
    //     perBlockGetterName: "kafePerBlock",
    //     ABI: KuKafeMasterchefABI,
    //     routerAddress: "0xA58350d6dEE8441aa42754346860E3545cc83cdA"
    // },
    {
        address: MasterchefEnum.MJT,
        rewardTokenAddress: "0x2cA48b4eeA5A731c2B54e7C3944DBDB87c0CFB6F",
        targetedPools: [1],
        perBlockGetterName: "mojitoPerBlock",
        ABI: MojitoSwapMasterchefABI,
        routerAddress: "0x8c8067ed3bC19ACcE28C1953bfC18DC85A2127F7"
    }
]


export const LSDFundRewardsDistribution = {
    DIVIDENDS: 0.2,
    BURN: 0.48,
    COMPOUND: 0.3,
    MARKETING: 0.02
}

export const assetsInfo: {
    [address: string]: {
        label: string;
        gradientId: string;
        legendColor: string
    }
} = {
    "0x1EE6b0F7302b3c48c5Fa89Cd0a066309D9AC3584": {
        label: "KUS-KCS LP on KuSwap",
        gradientId: "gradientKUS",
        legendColor: '#09dcff'
    },

    // "0xCD42aDaA456f1B95Cd4ad0aDFB1095762ee4225f": {
    //     label: "KAFE-KCS LP on KuSwap",
    //     gradientId: "gradientKAFE",
    //     legendColor: "#faf047"
    // },
    "0xa0d7C8aA789362CDf4FAAE24b9D1528eD5a3777f": {
        label: "MJT-KCS LP on MojitoSwap",
        gradientId: "gradientMJT",
        legendColor: "#3ce69e"
    }
}

export const distributionInfo: {[key in keyof typeof LSDFundRewardsDistribution]: {
    label: string;
    gradientId: string;
    legendColor: string;
}} = {
    DIVIDENDS:{
        label: "Loyalty dividends",
        gradientId: "gradient-1",
        legendColor: "#25c4ff"
    },
    BURN: {
        label: "Buyback and burn of TIKU",
        gradientId: "gradient-2",
        legendColor: "#e7704b"
    },
    COMPOUND: {
        label: "Auto-compounded",
        gradientId: "gradient-3",
        legendColor: "#3eff84"
    },
    MARKETING: {
        label: "Marketing",
        gradientId: "gradient-4",
        legendColor: "#e736d3"
    }
}

export const strategies = {
    "KUSWAP_LP": "0x0C1a3813744951388FF7f971AD103591E0A1C12A",
    // "KUKAFE_LP": "0x8B244e9Cb77A4e671d47c6fe76b00Fd47B5B126B",
    "MOJITO_LP": "0xb8c0b5D73d1C5DDC61A25bC934A6B150e232d55B"
}
