import { Web3ReactProvider } from "@web3-react/core";
import { Windmill } from "@windmill/react-ui";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { NftProvider } from "use-nft";
import App from "./App";
import "./assets/css/tailwind.output.css";
import ThemedSuspense from "./components/ThemedSuspense";
import { ContractsProvider, provider } from "./contexts/ContractsContext";
import { LSDProvider } from "./contexts/LSDContext";
import { MarketProvider } from "./contexts/MarketContext";
import { NFTsProvider } from "./contexts/NFTsContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { WalletConnectorProvider } from "./contexts/WalletConnectorContext";
import "./custom.css";
import * as serviceWorker from "./serviceWorker";
import { getLibrary } from "./utils/web3";
import windmillTheme from "./windmillTheme";

ReactDOM.render(
    <SidebarProvider>
        <Suspense fallback={<ThemedSuspense />}>
            <Windmill dark theme={windmillTheme}>
                <WalletConnectorProvider>
                    <Web3ReactProvider getLibrary={getLibrary}>
                        <ContractsProvider>
                            <LSDProvider>
                                <MarketProvider>
                                    <NFTsProvider>
                                        <NftProvider fetcher={["ethers", { provider }]}>
                                            <App />
                                        </NftProvider>
                                    </NFTsProvider>
                                </MarketProvider>
                            </LSDProvider>
                        </ContractsProvider>
                    </Web3ReactProvider>
                </WalletConnectorProvider>
            </Windmill>
        </Suspense>
    </SidebarProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: (registration: ServiceWorkerRegistration) => {
        // Skip waiting for all tabs to close and update ASAP. Then just refresh.
        // TODO: We can later change this to a small pop-up window in the top right, for instance
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }
        window.location.reload();
    },
});
