/* eslint-disable no-unused-vars */
import koffeeRouterABI from './abi/koffeeRouter.abi.json';
import LSDBagABI from "./abi/LSDBagAttachment.json";
import tikuABI from './abi/TIKUToken.abi.json';
import network from './network';

export interface contractConfig {
    address: string;
    ABI: any[];
}

export enum Contracts {
    TIKU = 'TIKU',
    KOFFEE_ROUTER = 'KOFFEE_ROUTER',
    LSD_BAG = 'LSD_BAG'
}

export const contracts: {[key in Contracts]: contractConfig} = {
  [Contracts.TIKU]: {
    address: network.tikuContractAddress,
    ABI: tikuABI
  },
  [Contracts.KOFFEE_ROUTER]: {
    address: network.koffeeRouterAddress,
    ABI: koffeeRouterABI
  },
  [Contracts.LSD_BAG]: {
    address: network.LSDBagContractAddress,
    ABI: LSDBagABI
  }
}
