import { isAddress } from "@ethersproject/address";
import { useWeb3React } from "@web3-react/core";
import React, {
    createContext,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState
} from "react";
import {
    MarketContextAction,
    MarketContextActionType,
    MarketContextState
} from "../types/contexts";
import { updateAccountMarketData, updateGeneralMarketData } from "../utils/marketData";

const initialState = {
    tikuPrice: 0,
    tikuPriceKCS: 0,
    tikuBalance: 0,
    paid: 0,
    lastPaid: 0,
    nextPayoutProgress: 0,
    totalPaid: 0,
    nextPayoutValue: 0,
    kcsBalance: 0,
    kcsPrice: 0,
    totalSentToLSDFund: 0,
};

const reducer = (
    state: MarketContextState = initialState,
    action: MarketContextAction
): MarketContextState => {
    switch (action.type) {
        case MarketContextActionType.SET_TIKU_PRICE:
            return { ...state, tikuPrice: action.payload };
            case MarketContextActionType.SET_TIKU_PRICE_KCS:
            return { ...state, tikuPriceKCS: action.payload };
        case MarketContextActionType.SET_TIKU_BALANCE:
            return { ...state, tikuBalance: action.payload };
        case MarketContextActionType.SET_PAID_KCS:
            return { ...state, paid: action.payload };
        case MarketContextActionType.SET_LAST_PAID:
            return { ...state, lastPaid: action.payload };
        case MarketContextActionType.SET_PAYOUT_PROGRESS:
            return { ...state, nextPayoutProgress: action.payload };
        case MarketContextActionType.SET_TOTAL_PAID:
            return { ...state, totalPaid: action.payload };
        case MarketContextActionType.SET_NEXT_PAYOUT_VALUE:
            return { ...state, nextPayoutValue: action.payload };
        case MarketContextActionType.SET_KCS_BALANCE:
            return { ...state, kcsBalance: action.payload };
        case MarketContextActionType.SET_KCS_PRICE:
            return { ...state, kcsPrice: action.payload };
        case MarketContextActionType.SET_TOTAL_SENT_TO_LSD:
            return { ...state, totalSentToLSDFund: action.payload };
    }
};

export const MarketContext = createContext<{
    state: MarketContextState;
    dispatch: React.Dispatch<MarketContextAction>;
    updateAddress: (newAddress: string) => void;
}>({ state: initialState, dispatch: () => null, updateAddress: () => {} });

let timer: number;

export const MarketProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { account } = useWeb3React();

    const [address, setAddress] = useState<string>(localStorage.getItem("address") || "");

    const [refreshAddressData, setRefreshAddressData] = useState<boolean>(true);
    const [refreshGeneralData, setRefreshGeneralData] = useState<boolean>(true);

    useEffect(() => {
        if (account) {
            setAddress(account);
        }
    }, [account]);

    // Refreshes market data connected with account whenever address changes or every 9s
    useEffect(() => {
        if (localStorage.getItem("address") !== address) {
            localStorage.setItem("address", address || "");
        }

        if (isAddress(address || "")) {
            updateAccountMarketData(address, dispatch);
            window.clearTimeout(timer);
            timer = window.setTimeout(function () {
                setRefreshAddressData(!refreshAddressData);
            }, 90000);
        }
    }, [address, refreshAddressData]);

    // Refreshes general market data every 5s
    useEffect(() => {
        updateGeneralMarketData(dispatch);
        setTimeout(function () {
            setRefreshGeneralData((prev) => !prev);
        }, 50000);
    }, [refreshGeneralData]);

    const updateAddress = useCallback((newAddress: string) => setAddress(newAddress.trim()), []);

    const contextValue = useMemo(() => {
        return { state, dispatch, updateAddress };
    }, [state, dispatch, updateAddress]);

    return <MarketContext.Provider value={contextValue}>{children}</MarketContext.Provider>;
};
