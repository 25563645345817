import { Contract, ContractInterface, ethers } from "ethers";
import toast from "react-hot-toast";
import IERC1155ABI from "../constants/abi/IERC1155MetadataURI.json";
import IERC20ABI from "../constants/abi/IERC20.json";
import IERC721ABI from "../constants/abi/IERC721Metadata.json";
import { provider } from "../contexts/ContractsContext";
import { IERC1155MetadataURI } from "../typechain/IERC1155MetadataURI";
import { IERC20 } from "../typechain/IERC20";
import { IERC721Metadata } from "../typechain/IERC721Metadata";


export const getViewingContract = <T extends Contract>(ABI: ContractInterface) => (address: string): T => new ethers.Contract(address, ABI, provider) as T;

export const getERC20Contract = getViewingContract<IERC20>(IERC20ABI);

export const getERC721Contract = getViewingContract<IERC721Metadata>(IERC721ABI);

export const getERC1155Contract = getViewingContract<IERC1155MetadataURI>(IERC1155ABI);

interface writeTransactionOptions {
    onSuccessCallback?: () => void;
    onErrorCallback?: () => void;
    onSentCallback?: () => void;
    successMessage?: string;
    errorMessage?: string;
    sentMessage?: string;
  }
  
  type writeTransactionHandler = (transaction: () => Promise<ethers.providers.TransactionResponse>, options?: writeTransactionOptions) => void
  

export const writeTransactionHandler = (transaction: () => Promise<ethers.providers.TransactionResponse>, options?: writeTransactionOptions): void => {
    const toastId = Date.now().toString()
  
    const handleError = (err: Error) => {
      console.log(err)
      toast.error(`${options?.errorMessage
        ? options.errorMessage
        : 'Transaction failed.'}: ${err.message}`,
      { id: toastId, duration: 5000 })
      if (options?.onErrorCallback) {
        options.onErrorCallback()
      }
    }
  
    transaction()
      .then(tx => {
        toast.loading(options?.sentMessage
          ? options.sentMessage
          : 'Transaction sent.',
        { id: toastId })
        tx.wait()
          .then(() => {
            toast.success(options?.successMessage
              ? options.successMessage
              : 'Transaction was successful.',
            { id: toastId, duration: 5000 })
  
            if (options?.onSuccessCallback) {
              options.onSuccessCallback()
            }
          }).catch(handleError)
      }).catch(handleError) }