import React, { ReactNode, useCallback, useMemo, useState } from "react";

interface WalletConnectorContextState {
    isOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
}

const initialState = {
    isOpen: false,
    openModal: () => {},
    closeModal: () => {}
}

export const WalletConnectorContext = React.createContext<WalletConnectorContextState>(initialState);

export const WalletConnectorProvider = ({children} : {children: ReactNode}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    
    const openModal = useCallback(
        () => {
            setIsOpen(true);
        },
        [],
    )

    const closeModal = useCallback(
        () => {
            setIsOpen(false);
        },
        [],
    )

    const value = useMemo(() => ({
        isOpen,
        openModal,
        closeModal
    }), [isOpen, openModal, closeModal]);

    return <WalletConnectorContext.Provider value={value}>{children}</WalletConnectorContext.Provider>;
}